import {
  createSowing,
  seedClasses,
  seederSystems,
  sowings,
} from '@/modules/sowing/mock/sowingSeeds.js'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

const sowingRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/semeaduras',
      result: () => {
        let filteredSowings = sowings
        filteredSowings = sowings.filter(
          (sowing) => sowing.plot_enabled && !sowing.deleted
        )
        return APIFailureWrapper({
          content: filteredSowings,
          errorMessage: 'Erro na listagem de semeaduras',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/seed-class',
      result: () => {
        return APIFailureWrapper({
          content: seedClasses,
          errorMessage: 'Erro na listagem de classes de sementes',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/seeder-system',
      result: () => {
        return APIFailureWrapper({
          content: seederSystems,
          errorMessage: 'Erro na listagem de sistemas de semeadora',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/semeaduras',
      result: ({ requestBody }) => {
        const responseObj = JSON.parse(requestBody)
        createSowing(responseObj)
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação de semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/semeaduras/:sowing_id',
      result: ({ params }) => {
        sowings.forEach((sowing) => {
          if (sowing.id == params.sowing_id) sowing.deleted = true
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção da semeadura',
        })
      },
    },
    'on'
  ),
]

export { sowingRoutes }
