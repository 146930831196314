import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import { seasonCrops } from '@/services/__mocks__/seasonCropsSeeds'

const seasonCropsRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/season-crops',
      result: () => {
        return APIFailureWrapper({
          content: seasonCrops,
          errorMessage: 'Erro ao buscar season crops',
        })
      },
    },
    'on'
  ),
]

export { seasonCropsRoutes }
