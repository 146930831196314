export default {
  login: {
    welcome_back: 'Bem-vindo(a) de volta',
    welcome_message: 'Faça o login para acessar a plataforma',
    email: 'E-mail',
    send_email: 'Enviar e-mail',
    digit_email: 'Digite seu e-mail',
    password: 'Senha',
    digit_password: 'Digite sua senha',
    forgot_password: 'Esqueci a senha',
    back_to_login: 'Voltar para login',
    recover_password: 'Recuperar senha',
    platform_access: 'Acessar a plataforma',
    need_help: 'Precisa de ajuda?',
    contact_us: 'Entre em contato',
    select_account: 'Selecionar a conta',
    check_email: 'Verifique seu e-mail',
    check_email_text:
      'Lá você terá instruções para </br>redefinição da sua senha',

    error_release_date: 'Seu acesso será liberado em {date}',
    error_message_release_date:
      'Caso tenha alguma dúvida, entre em contato com o nosso suporte pelo e-mail suporte@digifarmz.com',
    back: 'Voltar',
  },
}
