export default {
  login: {
    welcome_back: 'Welcome back',
    welcome_message: 'Login to access the platform',
    send_email: 'Send email',
    email: 'Email',
    digit_email: 'Enter your email',
    password: 'Password',
    digit_password: 'Enter your password',
    forgot_password: 'Forgot password',
    back_to_login: 'back to login',
    recover_password: 'Recover password',
    platform_access: 'Access the platform',
    need_help: 'Need help?',
    contact_us: 'Contact us',
    select_account: 'Select an account',
    check_email: 'Check your email',
    check_email_text:
      'There you will have instructions for </br>resetting your password',

    error_release_date: 'Your access will be released on {date}',
    error_message_release_date:
      'If you have any questions, please contact our support at suporte@digifarmz.com',
    back: 'Back',
  },
}
