import api from '@/services/api'

function initialState() {
  return {
    semeaduras: [],
    allSowings: [],
    fazenda: null,
    sementes: [],
    emergencia: null,
    semeadura: null,
    fazendas: [],
    areaRestante: 0,
    isLoading: false,
  }
}

export const semeaduras = {
  namespaced: true,

  state: {
    initialState: initialState,
    semeaduras: initialState().semeaduras,
    allSowings: initialState().allSowings,
    fazenda: initialState().fazenda,
    sementes: initialState().sementes,
    emergencia: initialState().emergencia,
    semeadura: initialState().semeadura,
    fazendas: initialState().fazendas,
    areaRestante: initialState().areaRestante,
    isLoading: initialState().isLoading,
  },

  mutations: {
    SET_SEMEADURAS(state, semeaduras) {
      state.semeaduras = semeaduras
    },
    SET_ALL_SOWINGS(state, sowings) {
      state.allSowings = sowings
    },
    SET_FAZENDA(state, fazenda) {
      state.fazenda = fazenda
    },
    SET_SEMENTES(state, sementes) {
      state.sementes = sementes
    },
    SET_FAZENDAS(state, fazendas) {
      state.fazendas = fazendas
    },
    SET_EMERGENCIA(state, emergencia) {
      state.emergencia = emergencia
    },
    SET_SEMEADURA(state, semeadura) {
      state.semeadura = semeadura
    },
    SET_AREA_RESTANTE(state, areaRestante) {
      state.areaRestante = areaRestante
    },
    SET_LOADING_LOAD_SOWINGS_FILTER(state, isLoading) {
      state.isLoading = isLoading
    },
  },

  getters: {
    semeaduras: (state) => {
      return state.semeaduras
    },
    allSowings: (state) => {
      return state.allSowings
    },
    sementes: (state) => {
      return state.sementes
    },
    fazenda: (state) => {
      return state.fazenda
    },
    emergencia: (state) => {
      return state.emergencia
    },
    semeadura: (state) => {
      return state.semeadura
    },
    cultivar: (state) => {
      return state.cultivar
    },
    fazendas: (state) => {
      return state.fazendas
    },
    areaRestante: (state) => {
      return state.areaRestante
    },
    isLoading: (state) => {
      return state.isLoading
    },
    plantingsByCrop(state, getters, rootState) {
      const cropId = rootState.crops.currentCrop?.id
      return state.semeaduras.filter((planting) => planting.crop_id == cropId)
    },
    sowingsReadyToHarvest(state, getters, rootState) {
      const cropId = rootState.crops.currentCrop?.id
      return state.semeaduras.filter(
        (planting) =>
          planting.crop_id == cropId &&
          planting.ready_to_harvest &&
          !planting.colheita
      )
    },
  },

  actions: {
    async loadFazendas({ commit, state }) {
      const { data } = await api.fazendas.all()

      if (!data) throw new Error('Erro ao buscar fazendas')

      await commit('SET_FAZENDAS', data)

      const fazenda = data.find((fazenda) => fazenda.id == state.fazenda.id)

      await commit('SET_FAZENDA', fazenda)

      return true
    },
    async modalDataEmergencia({ commit }, data_semeadura) {
      const { data } = await api.sementes.getDataEmergencia(data_semeadura)

      if (!data) throw new Error('Erro ao buscar data de emergência')

      await commit('SET_EMERGENCIA', data)

      return true
    },
    async loadSowings({ commit, rootState }, filters) {
      const { start_period, end_period } =
        rootState.seasonsCrops.currentSeasonCrop
      const farmId = rootState.farms.currentFarm.id

      const { data } = await api.semeaduras.findByFazenda(
        farmId,
        filters,
        start_period,
        end_period
      )

      if (!data) throw new Error('Erro ao buscar as semeaduras')

      await commit('SET_SEMEADURAS', data)

      return true
    },
    async loadAllSowings({ commit, rootState }, filters) {
      const farmId = rootState.farms.currentFarm.id

      const { data } = await api.semeaduras.findByFazenda(farmId, filters)

      if (!data) throw new Error('Erro ao buscar as semeaduras')

      await commit('SET_ALL_SOWINGS', data)

      return true
    },
    async getSeedsBySelectedCrop({ commit, rootState }, farmId) {
      const cropId = rootState.crops.currentCrop?.id
      const seasonCrop = rootState.seasonsCrops.currentSeasonCrop

      if (!cropId) throw new Error('Undefined current crop')

      const { data } = await api.sementes.getSeeds(
        farmId,
        cropId,
        seasonCrop?.id,
        seasonCrop?.start_period,
        seasonCrop?.end_period
      )

      if (!data) throw new Error('Erro ao buscar sementes')

      await commit('SET_SEMENTES', data)

      return true
    },
    async loadSowingsFilter({ commit, rootState }) {
      try {
        commit('SET_LOADING_LOAD_SOWINGS_FILTER', true)

        const { start_period, end_period } =
          rootState.seasonsCrops.currentSeasonCrop

        const farmId = rootState.farms.currentFarm.id

        const { data } = await api.semeaduras.findByFazenda(
          farmId,
          ['semente', 'cultivar_regiao_parametros', 'colheita', 'status'],
          start_period,
          end_period
        )

        if (!data) throw new Error('Erro ao buscar as semeaduras')

        await commit('SET_SEMEADURAS', data)

        commit('SET_LOADING_LOAD_SOWINGS_FILTER', false)

        return true
      } catch (e) {
        commit('SET_LOADING_LOAD_SOWINGS_FILTER', false)
        return false
      }
    },
    async modalSave(actions, semeadura) {
      const params = {
        semente_id: semeadura.semente_id,
        talhao_id: semeadura.talhao_id,
        area: semeadura.area_semeada,
        data_emergencia: semeadura.date_emergencia,
        fazenda_id: semeadura.fazenda_id,
        data_semeadura: semeadura.date_realizada,
        id: semeadura.id,
        quantidade: semeadura.quantidade_semeada,
        origem: semeadura.origem,
        seeder_system_id: semeadura.seeder_system_id,
        seed_class_id: semeadura.seed_class_id,
        estimated_cycle: semeadura.estimated_cycle,
        germinative_power: semeadura.germinative_power,
        seed_vigor: semeadura.seed_vigor,
        line_spacing: semeadura.line_spacing,
        population_lines: semeadura.population_lines,
        operating_speed: semeadura.operating_speed,
        seed_treatment: semeadura.seed_treatment,
        inoculant: semeadura.inoculant,
      }
      try {
        if (semeadura.id) api.semeaduras.update(params)
        else api.semeaduras.create(params)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async modalAreaRestante({ commit }, talhao) {
      const response = await api.talhoes.areaRestante(talhao, talhao.fazenda_id)

      if (!response) throw new Error('Erro ao buscar area restante')

      await commit('SET_AREA_RESTANTE', response.data)
      return true
    },
    resetStore({ commit }) {
      commit('SET_SEMEADURAS', initialState().semeaduras)
      commit('SET_ALL_SOWINGS', initialState().allSowings)
      commit('SET_FAZENDA', initialState().fazenda)
      commit('SET_SEMENTES', initialState().sementes)
      commit('SET_FAZENDAS', initialState().fazendas)
      commit('SET_EMERGENCIA', initialState().emergencia)
      commit('SET_SEMEADURA', initialState().semeadura)
      commit('SET_AREA_RESTANTE', initialState().areaRestante)
      commit('SET_LOADING_LOAD_SOWINGS_FILTER', initialState().isLoading)
    },
  },
}
