import http, { noAuthHttp } from '@/utils/http'

const changePasswordUrl = '/change-password'

const auth = {
  login: (email, password) => {
    return noAuthHttp.post('/digifarmz-login', { email, password })
  },
  recoverPassword: (email) => noAuthHttp.post('/recover-password', { email }),
  selectAccount: (accountId) =>
    http.post('/select-account', { account_id: accountId }),
  logout: () => http.post('/logout'),
  refreshToken: () => http.post('/token-refresh'),
  changePassword: (oldPassword, password, passwordConfirmation) =>
    http.post(changePasswordUrl, {
      password_old: oldPassword,
      password: password,
      password_confirmation: passwordConfirmation,
    }),
  deleteTemporaryPassword: (userId) =>
    http.delete(`/users/${userId}/recovery-password`),
}

export { changePasswordUrl }
export default auth
